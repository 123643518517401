var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LargeModal",
    {
      attrs: {
        size: "large",
        display: _vm.display,
        padding: "p-0",
        hideClose: "",
        background: "white",
      },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
      scopedSlots: _vm._u(
        [
          _vm.isOnboarding
            ? {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-center text-grey font-semibold cursor-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [_vm._v(" Go to Dashboard ")]
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _c("div", { staticClass: "px-4 lg:p-0 heading-part" }, [
        _c("div", { staticClass: "md:w-3/5 mx-auto flex justify-between" }, [
          _c("div", { staticClass: "p-2" }, [
            _c(
              "h4",
              { staticClass: "text-lg md:text-xl text-white font-bold" },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            ),
            _c("p", { staticClass: "text-lightGrey-1 text-sm mt-2" }, [
              _vm._v(" " + _vm._s(_vm.description) + " "),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "vue-ellipse-progress",
                {
                  attrs: {
                    progress: _vm.onboardingSummary.percentCompletion,
                    size: _vm.progressBar.size,
                    emptyColor: "#a981d6",
                    color: "white",
                    thickness: _vm.progressBar.thickness,
                    "empty-thickness": _vm.progressBar.emptyThickness,
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "ellipse-text text-white text-sm font-bold",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.onboardingSummary.percentCompletion) +
                          "% "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "flex items-center ridges" },
          _vm._l(10, function (i) {
            return _c(
              "svg",
              {
                key: i,
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "303",
                  height: "44",
                  fill: "none",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M253.001 0h-12.132c-3.169 7.83705-9.966 13.2552-17.84 13.2552-7.874 0-14.672-5.41815-17.84-13.2552h-12.137c-3.168 7.83705-9.965 13.2552-17.84 13.2552-7.874 0-14.671-5.41815-17.84-13.2552h-12.141c-3.169 7.83705-9.966 13.2552-17.84 13.2552-7.874 0-14.672-5.41815-17.84-13.2552H97.4112c-3.1684 7.83705-9.9658 13.2552-17.84 13.2552S64.8996 7.83705 61.7312 0H49.5937c-3.1684 7.83705-9.9658 13.2552-17.84 13.2552S17.0821 7.83705 13.9137 0H0v43.7546h302.592V0h-13.911c-3.168 7.83705-9.966 13.2552-17.84 13.2552-7.874 0-14.671-5.41815-17.84-13.2552Z",
                    fill: "#fff",
                  },
                }),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "md:w-3/5 mx-auto px-4 pt-2" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }