<template>
  <LargeModal
    size="large"
    :display="display"
    padding="p-0"
    hideClose
    @close="$emit('close')"
    background="white"
  >
    <div class="px-4 lg:p-0 heading-part">
      <div class="md:w-3/5 mx-auto flex justify-between">
        <div class="p-2">
          <h4 class="text-lg md:text-xl text-white font-bold">
            {{ title }}
          </h4>
          <p class="text-lightGrey-1 text-sm mt-2">
            {{ description }}
          </p>
        </div>
        <div>
          <vue-ellipse-progress
            :progress="onboardingSummary.percentCompletion"
            :size="progressBar.size"
            emptyColor="#a981d6"
            color="white"
            :thickness="progressBar.thickness"
            :empty-thickness="progressBar.emptyThickness"
          >
            <p class="ellipse-text text-white text-sm font-bold">
              {{ onboardingSummary.percentCompletion }}%
            </p>
          </vue-ellipse-progress>
        </div>
      </div>

      <!-- <TopSvg class="top-left-coins hidden md:block" />
      <TopRight class="top-right-coins" /> -->
      <div class="flex items-center ridges">
        <svg
          v-for="i in 10"
          :key="i"
          xmlns="http://www.w3.org/2000/svg"
          width="303"
          height="44"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M253.001 0h-12.132c-3.169 7.83705-9.966 13.2552-17.84 13.2552-7.874 0-14.672-5.41815-17.84-13.2552h-12.137c-3.168 7.83705-9.965 13.2552-17.84 13.2552-7.874 0-14.671-5.41815-17.84-13.2552h-12.141c-3.169 7.83705-9.966 13.2552-17.84 13.2552-7.874 0-14.672-5.41815-17.84-13.2552H97.4112c-3.1684 7.83705-9.9658 13.2552-17.84 13.2552S64.8996 7.83705 61.7312 0H49.5937c-3.1684 7.83705-9.9658 13.2552-17.84 13.2552S17.0821 7.83705 13.9137 0H0v43.7546h302.592V0h-13.911c-3.168 7.83705-9.966 13.2552-17.84 13.2552-7.874 0-14.671-5.41815-17.84-13.2552Z"
            fill="#fff"
          />
        </svg>
      </div>
    </div>
    <div class="md:w-3/5 mx-auto px-4 pt-2">
      <slot />
    </div>
    
    <template v-slot:footer v-if="isOnboarding">
      <p class="text-center text-grey font-semibold cursor-pointer" @click="$emit('close')">
        Go to Dashboard
      </p>
    </template>
  </LargeModal>
</template>
<script>
  import Vue from "vue";
  import { mapState } from "vuex";
  import { LargeModal } from "@/UI/Modals";
  // import TopSvg from "./top-left.vue";
  // import TopRight from "./Top-right.vue";
  import VueEllipseProgress from "vue-ellipse-progress";
  Vue.use(VueEllipseProgress);
  export default {
    components: {
      // TopSvg,
      // TopRight,
      LargeModal,
    },
    props: {
      title: {
        type: String,
        default: "Congratulations",
        required: false,
      },
      description: {
        type: String,
        default: "",
        required: false,
      },
      display: {
        type: Boolean,
        default: false,
        required: true,
      },
      isOnboarding:{
        typpe: Boolean,
        default: false,
        required: false
      }
    },
    data: () => ({
      progressBar: {
        size: 61,
        progress: 50,
        emptyThickness: 5,
        thickness: 5,
      },
    }),
    computed: {
      ...mapState({
        onboardingSummary: (state) => state?.dashboard?.dashboardData.onboardingSummary,
      }),
    },
  };
</script>
<style scoped>
  .heading-part {
    position: relative;
    background: linear-gradient(
        217.04deg,
        #c6003b -191.09%,
        #6a30c3 77.43%,
        #63dfdf 156.06%
      ),
      #6a30c3;
    height: 13rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-top: -1rem;
    overflow: hidden;
  }
  .ridges {
    /* margin-top: -1rem; */
    position: absolute;
    bottom: -0.2rem;
    left: 0;
  }
  .top-left-coins {
    position: absolute;
    top: -2.5rem;
    left: 0;
    z-index: 2;
  }
  .top-right-coins {
    position: absolute;
    top: -1rem;
    right: 0;
    z-index: 2;
  }
  @media (min-width: 768px) {
    .heading-part {
      height: 15rem;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
</style>
